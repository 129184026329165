import { DataGridPro, DataGridProProps, GridColDef, GridValidRowModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { ReactElement, useCallback, useMemo, useState } from "react";
import colors from "../../../colors";
import { useMountEffect } from "../../../utils/hooks";
import { SxProps, Theme } from "@mui/material";

const anchorField = '__panelAnchor__';

const CabDataGrid = <T extends GridValidRowModel>(props: DataGridProProps): ReactElement => {
  const gridApiRef = useGridApiRef();
  const apiRef = props.apiRef || gridApiRef;

  // The grid needs to re-render at least once in order to trigger the panel anchoring logic down below,
  //    otherwise the ref hasn't been set yet so it will fail.
  const [, setForceRenderVar] = useState(false);
  useMountEffect(() => {
    setTimeout(() => setForceRenderVar(true), 50);
  });

  const sx = useMemo<SxProps<Theme>>(() => ({
    border: 0,
    borderBottom: 1,
    borderColor: colors.black100,
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: colors.black100,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600
    },
    "& .MuiDataGrid-footerContainer": {
      borderLeft: 1,
      borderRight: 1,
      borderColor: colors.black100,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4
    },
    "& .MuiDataGrid-virtualScroller": {
      borderLeft: 1,
      borderRight: 1,
      borderColor: colors.black100,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4
    },
    ...props.sx,
    '& .gridHeaderNoPadding': {
      padding: 0,
    },
    '& .gridCellNoPadding': {
      padding: 0,
    }
  }), [props.sx]);

  const newColumns = useMemo(() => [
    ...props.columns,
    {
      field: anchorField,
      type: "string", 
      disableColumnMenu: true,
      resizable: false,
      hideable: false,
      width: 1,
      minWidth: 1,
      headerName: '',
      filterable: false,
      sortable: false,
      headerClassName: "gridHeaderNoPadding",
      cellClassName: "gridCellNoPadding"
    } satisfies GridColDef
  ], [props.columns]);

  const getTogglableColumns = useCallback((columns: GridColDef[]): GridColDef["field"][] => {
    // Don't show the anchor field or any empty-string named fields
    const prefilteredColumns = columns.filter(colDef => !['', anchorField].includes(colDef.field));

    const togglableColumnsFunc = props.slotProps?.columnsManagement?.getTogglableColumns;
    if (togglableColumnsFunc) {
      return togglableColumnsFunc(prefilteredColumns);
    }

    return prefilteredColumns.map(colDef => colDef.field);
  }, [props.slotProps?.columnsManagement?.getTogglableColumns]);

  const initialState = useMemo(() => ({
    ...props.initialState,
    pinnedColumns: {
      ...props.initialState?.pinnedColumns,
      right: [
        ...props.initialState?.pinnedColumns?.right || [],
        anchorField
      ]
    }
  }), [props.initialState]);

  const slotPropsWithToolbarAnchor = useMemo(() => {
    const slotProps = {...props.slotProps};
    const getColumnHeaderElement = apiRef?.current?.getColumnHeaderElement;
    const anchorFieldEl = getColumnHeaderElement ? getColumnHeaderElement(anchorField) : undefined;

    if (anchorFieldEl) {
      slotProps.panel = {
        ...slotProps.panel,
        anchorEl: anchorFieldEl,
        placement: "bottom-end",
      };
    }
    slotProps.columnsManagement = {
      ...slotProps.columnsManagement,
      getTogglableColumns
    };

    return slotProps;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, props.slotProps, getTogglableColumns, apiRef?.current.rootElementRef?.current]);

  // NOTE: Make absolutely sure that all non-primitive props are static or memoized!
  return <DataGridPro<T> 
    {...props} 
    initialState={initialState} 
    columns={newColumns} 
    apiRef={apiRef} 
    slotProps={slotPropsWithToolbarAnchor}
    sx={sx}
  />;
};

export default CabDataGrid;
